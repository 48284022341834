<template>
  <vx-card no-shadow>
    <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
      <!-- Info -->
      <vs-input
      class="w-full mb-base" label-placeholder="First Name"
      :danger="validation.first_name?true:false"
      :danger-text="validation.first_name?validation.first_name[0]:''"
      :value="form.first_name"
      @input="updateForm({key: 'first_name', value: $event})"  />

      <vs-input
      class="w-full mb-base"
      label-placeholder="Middle Name"
      :danger="validation.middle_name?true:false"
      :danger-text="validation.middle_name?validation.middle_name[0]:''"
      :value="form.middle_name"
      @input="updateForm({key: 'middle_name', value: $event})"/>

      <vs-input
      class="w-full mb-base"
      label-placeholder="Last Name"
      :danger="validation.last_name?true:false"
      :danger-text="validation.last_name?validation.last_name[0]:''"
      :value="form.last_name"
      @input="updateForm({key: 'last_name', value: $event})" />

      <vs-input
      class="w-full"
      label-placeholder="Email"
      :danger="validation.email?true:false"
      :danger-text="validation.email?validation.email[0]:''"
      :value="form.email"
      @input="updateForm({key: 'email', value: $event})" />

      <vs-input
      class="w-full mb-base"
      label-placeholder="Username"
      :danger="validation.username?true:false"
      :danger-text="validation.username?validation.username[0]:''"
      :value="form.username"
      @input="updateForm({key: 'username', value: $event})" />

      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button button="submit" class="ml-auto mt-2" >Save Changes</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetForm">Reset</vs-button>
      </div>
      </form>
  </vx-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import loaderMixin from '../../../../mixins/loaderMixin';
import axios from "axios";
import * as storage from "@/utils/storage";
export default {
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'setting/getFormObj',
        validation: 'validation/getValidationErrors',
    })
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
    this.fetchAndSetUser();
  },
  methods: {
    ...mapActions({
      updateForm: 'setting/updateForm',
      clearForm: 'setting/clearForm',
      fetchAndSetUser: 'setting/fetchAndSetUser',
      updateUserAction: 'setting/updateUserAction',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    resetForm(){
      this.clearForm();
      this.clearValidationErrors();
    },
    submitForm() {
      this.openLoading();
      this.updateUserAction(this.form)
        .then(response => {
           if(response.status === 202) {
             axios.get('v1/admin/me').then((res) => {
               storage.set('userInfo', JSON.stringify(res.data.user));
             }).then(() =>{
                 this.$store.commit('UPDATE_USER_INFO', JSON.parse(storage.get('userInfo')));
               }
             )
             this.closeLoading();
              this.$vs.notify({
                title: 'Password Updated',
                text: 'Password changed successfully.',
                color: 'success',
                position: 'top-right'
            });
           }
        }).catch((e) => this.closeLoading());
    },
  }
}
</script>
