<template>
  <vx-card no-shadow>
    <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
      <vs-input 
      class="w-full mb-base" 
      label-placeholder="Old Password" 
      :danger="validation.old_password?true:false"
      :danger-text="validation.old_password?validation.old_password[0]:''"
      :value="form.old_password"
      type="password"
      @input="updateForm({key: 'old_password', value: $event})" />

      <vs-input 
      class="w-full mb-base" 
      label-placeholder="New Password" 
      :danger="validation.new_password?true:false"
      :danger-text="validation.new_password?validation.new_password[0]:''"
      :value="form.new_password"
      type="password"
      @input="updateForm({key: 'new_password', value: $event})" />

      <vs-input 
      class="w-full mb-base" 
      label-placeholder="Confirm Password" 
      :danger="validation.confirm_password?true:false"
      :danger-text="validation.confirm_password?validation.confirm_password[0]:''"
      :value="form.confirm_password"
      type="password"
      @input="updateForm({key: 'confirm_password', value: $event})" />

      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button button="submit" class="ml-auto mt-2" >Save Changes</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetForm">Reset</vs-button>
      </div>
      </form>
  </vx-card>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import loaderMixin from '../../../../mixins/loaderMixin';
export default {
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'setting/getPasswordFormObj',
        validation: 'validation/getValidationErrors',
    })
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
  },
  methods: {
    ...mapActions({
      updateForm: 'setting/updateForm',
      clearForm: 'setting/clearForm',
      updateUserPasswordAction: 'setting/updateUserPasswordAction',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    resetForm(){
      this.clearForm();
      this.clearValidationErrors();
    },
    submitForm() {  
      this.clearValidationErrors();
      this.openLoading();
      this.updateUserPasswordAction(this.form)
        .then(response => {
          console.log(response)
           if(response.status === 200) {
             this.closeLoading();
            this.$vs.notify({ 
                title: 'Password Updated',
                text: 'Password changed successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.clearForm();
           }
        }).catch((e) => this.closeLoading());
    },
  }
}
</script>
